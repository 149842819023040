import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import api from "../api";
import { OrderItem } from "../../api/api";
import { useTheme } from "@mui/material/styles";
import GenericTable from "../Table/Table";
import DeleteFormDialog from "./DeleteFormDialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

interface AddOrderOrderItemFormDialogProps {
  open: boolean;
  handleClose: () => void;
}

const AddOrderOrderItemFormDialog: React.FC<
  AddOrderOrderItemFormDialogProps
> = ({ open, handleClose }) => {
  const theme = useTheme();

  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [selectedOrderItems, setSelectedOrderItems] = React.useState<string[]>(
    [],
  );
  const [openDeleteOrderItemDialog, setOpenDeleteOrderItemDialog] =
    React.useState(false);
  const [orderItemToDelete, setOrderItemToDelete] = React.useState<any | null>(
    null,
  );

  const handleOpenDeleteOrderItemDialog = (row: any) => {
    setOrderItemToDelete(row);
    setOpenDeleteOrderItemDialog(true);
  };

  const handleCloseDeleteOrderItemDialog = () => {
    setOrderItemToDelete(null);
    setOpenDeleteOrderItemDialog(false);
  };

  const handleDeleteOrderItemClick = () => {
    if (orderItemToDelete) {
      handleDeleteOrderItem(orderItemToDelete.order_item_id);
      handleCloseDeleteOrderItemDialog();
    }
  };

  React.useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const response = await api.order_items.getOrderItemsOrderItemsGet();
        const data = response.data.contents;
        setOrderItems(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des order items :",
          error,
        );
      }
    };
    fetchOrderItems();
  }, []);

  const handleDeleteOrderItem = (deletedOrderItemId: string) => {
    setOrderItems((prevOrderItems) =>
      prevOrderItems.filter(
        (order_item) => order_item.order_item_id !== deletedOrderItemId,
      ),
    );
  };

  const columnConfig = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const getActionsButton = (row: any) => {
    return (
      <>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleOpenDeleteOrderItemDialog(row)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        sx: { minWidth: "600px" },
      }}
    >
      <DialogTitle>Add Order Items</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please select the order items to add:
        </DialogContentText>

        <GenericTable
          columnsConfig={columnConfig}
          tableType={"order_item"}
          rows={orderItems}
          getRows={async () => {
            const response = await api.order_items.getOrderItemsOrderItemsGet();
            return response.data.contents;
          }}
          setRows={setOrderItems}
          getActionsButton={getActionsButton}
        />
      </DialogContent>
      {orderItemToDelete && (
        <DeleteFormDialog
          open={openDeleteOrderItemDialog}
          handleClose={handleCloseDeleteOrderItemDialog}
          order_item={orderItemToDelete}
          onDelete={handleDeleteOrderItemClick}
        />
      )}

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderOrderItemFormDialog;
