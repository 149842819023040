import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosResponse } from "axios";
import { Order, OrderOrderItem } from "../../api/api";
import api from "../api";

interface DeleteOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  order?: any;
  order_item?: any;
  order_order_item?: any;
  question?: any;
  order_staff?: any;
  onDelete: (orderId: string) => void;
  onEdit?: (order: any) => void;
}

const DeleteFormDialog: React.FC<DeleteOrderFormDialogProps> = ({
  open,
  handleClose,
  order,
  order_item,
  order_order_item,
  question,
  order_staff,
  onDelete,
  onEdit,
}) => {
  const handleDeleteOrder = async () => {
    try {
      if (order_order_item && onEdit) {
        const response: AxiosResponse =
          await api.order_order_items.deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
            order_order_item.order_order_item_id,
          );

        const data = {
          ...order,
          order_order_items: order.order_order_items.filter(
            (item: OrderOrderItem) => item !== order_order_item,
          ),
        };

        onEdit(data);
      } else if (order) {
        const response: AxiosResponse =
          await api.orders.deleteOrderOrdersOrderIdDelete(order.order_id);

        onDelete(order.order_id);
      } else if (order_item) {
        const response: AxiosResponse =
          await api.order_items.deleteOrderItemOrderItemsOrderItemIdDelete(
            order_item.order_item_id,
          );

        onDelete(order_item.order_item_id);
      } else if (question) {
        const response: AxiosResponse =
          await api.questions.deleteQuestionQuestionsQuestionIdDelete(
            question.question_id,
          );

        onDelete(question.question_id);
      } else if (order_staff) {
        const response: AxiosResponse =
          await api.order_staffs.deleteOrderStaffOrderStaffsOrderStaffIdDelete(
            order_staff.order_staff_id,
          );

        onDelete(order_staff.order_staff_id);
      } else if (order_order_item) {
        const response: AxiosResponse =
          await api.order_order_items.deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
            order_order_item.order_order_item_id,
          );

        onDelete(order_order_item.order_order_item_id);
      }

      handleClose();
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Delete Order</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteOrder} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFormDialog;
