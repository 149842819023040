import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddItemFormDialog from "../components/FormDialog/AddItemFormDialog";
import AddOrderFormDialog from "../components/FormDialog/AddOrderFormDialog";
import OrderItemFormDialog from "../components/FormDialog/OrderItemFormDialog";
import CollapseTable from "../components/Table/CollapseTable";
import api from "../components/api";
import moment from "moment";
import { Order, OrderOrderItem, User } from "../api";
import AddOrderOrderItemFormDialog from "../components/FormDialog/AddOrderOrderItemFormDialog";
import EditOrderFormDialog from "../components/FormDialog/EditOrderFormDialog";
import DeleteFormDialog from "../components/FormDialog/DeleteFormDialog";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReorderIcon from "@mui/icons-material/Reorder";
import { config } from "../components/api";
import { useNavigate } from "react-router-dom";
import GenericTable from "../components/Table/Table";
import Calendar from "../components/Calendar/Calendar";
import { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CalendarList from "../components/Calendar/CalendarList";

const OrderPage: React.FC = () => {
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openCreateOrderDialog, setOpenCreateOrderDialog] =
    React.useState(false);
  const [openOrderItemDialog, setOpenOrderItemDialog] = React.useState(false);
  const [orders, setOrders] = React.useState<any[]>([]);
  const [openEditOrderDialog, setOpenEditOrderDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDeleteOrderOrderItemDialog, setOpenDeleteOrderOrderItemDialog] =
    React.useState(false);
  const [openAddOrderOrderItemDialog, setOpenAddOrderOrderItemDialog] =
    React.useState(false);
  const [orderToEdit, setOrderToEdit] = React.useState<Order | null>(null);
  const [orderToDelete, setOrderToDelete] = React.useState<Order | null>(null);
  const [orderOrderItemToDelete, setOrderOrderItemToDelete] =
    React.useState<OrderOrderItem | null>(null);
  const [orderOrderItemToAdd, setOrderOrderItemToAdd] =
    React.useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [highlightedDays, setHighlightedDays] = React.useState<Dayjs[]>([]);
  const [isCalendarMode, setIsCalendarMode] = React.useState(true);

  React.useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userResponse = await api.users.getUserUserGet();
        setSelectedUser(userResponse.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error,
        );
      }
    };

    fetchCurrentUser();
  }, []);

  const handleOpenEditOrderDialog = (row: any) => {
    setOrderToEdit(row);
    setOpenEditOrderDialog(true);
  };

  const handleCloseEditOrderDialog = () => {
    setOrderToEdit(null);
    setOpenEditOrderDialog(false);
  };

  const handleOpenDeleteDialog = (row: any) => {
    setOrderToDelete(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOrderToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteOrderOrderItemDialog = (
    row: any,
    orderOrderItem: OrderOrderItem,
  ) => {
    setOrderToDelete(row);
    setOrderOrderItemToDelete(orderOrderItem);
    setOpenDeleteOrderOrderItemDialog(true);
  };

  const handleCloseDeleteOrderOrderItemDialog = () => {
    setOrderOrderItemToDelete(null);
    setOpenDeleteOrderOrderItemDialog(false);
  };

  const handleOpenAddOrderOrderItemDialog = (row: any) => {
    setOrderOrderItemToAdd(row);
    setOpenAddOrderOrderItemDialog(true);
  };

  const handleCloseAddOrderOrderItemDialog = () =>
    setOpenAddOrderOrderItemDialog(false);

  const handleSaveEditOrder = (updatedOrder: Order) => {
    if (handleEditOrder) handleEditOrder(updatedOrder);
    handleCloseEditOrderDialog();
  };

  const handleDeleteOrderClick = () => {
    if (orderToDelete && handleDeleteOrder) {
      handleDeleteOrder(orderToDelete.order_id);
      handleCloseDeleteDialog();
    }
  };

  const handleDeleteOrderOrderItemClick = () => {
    if (orderOrderItemToDelete) {
      handleCloseDeleteOrderOrderItemDialog();
    }
  };

  const handleAddOrderOrderItem = (updatedOrder: Order) => {
    if (handleEditOrder) handleEditOrder(updatedOrder);
    handleCloseAddOrderOrderItemDialog();
  };

  const handleCreateItemClick = () => setOpenCreateDialog(true);
  const handleCreateOrderClick = () => setOpenCreateOrderDialog(true);
  const handleOrderItemClick = () => setOpenOrderItemDialog(true);
  const handleCloseCreateDialog = () => setOpenCreateDialog(false);
  const handleCloseCreateOrderDialog = () => setOpenCreateOrderDialog(false);
  const handleCloseOrderItemDialog = () => setOpenOrderItemDialog(false);

  const handleAddOrder = (newOrder: any) => setOrders([newOrder, ...orders]);

  const handleEditOrder = (updatedOrder: any) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) =>
        order.order_id === updatedOrder.order_id ? updatedOrder : order,
      );
      updatedOrders.sort((a, b) =>
        a.user_id === updatedOrder.order_id
          ? -1
          : b.user_id === updatedOrder.order_id
            ? 1
            : 0,
      );
      return updatedOrders;
    });
  };

  const handleDeleteOrder = (deletedOrderId: string) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.filter(
        (order) => order.order_id !== deletedOrderId,
      );
      return updatedOrders;
    });
  };

  const columnConfig = [
    { id: "expand", label: "", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 170 },
    { id: "type", label: "Type", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const detailColumnsAdmin = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const detailColumnsClient = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
  ];

  const getRows = async () => {
    let orders: any[] = [];
    let allOrderItems: any[] = [];

    if (config.role === "staff") {
      const orderStaffResponse =
        await api.order_staffs.getUsersOrderStaffsUsersOrderStaffsGet();
      const orderStaffContents = orderStaffResponse.data.contents;

      orders = orderStaffContents
        .filter((orderStaff) => Object.keys(orderStaff.order).length > 0)
        .map((orderStaff: any) => ({
          status: orderStaff.order.status,
          date: orderStaff.order.date,
          type: orderStaff.order.type,
          user_id: orderStaff.user_id,
          order_id: orderStaff.order_id,
          user: {
            first_name: orderStaff.user.first_name,
            last_name: orderStaff.user.last_name,
          },
        }));
    } else {
      const response = await api.orders.getOrdersOrdersGet();
      orders = response.data.contents;

      const orderOrderItemResponse =
        await api.order_order_items.getOrderOrderItemsOrderOrderItemsGet();
      allOrderItems = orderOrderItemResponse.data.contents;
    }

    return orders.map((order: any) => {
      const orderDate = moment(order.date).format("YYYY/MM/DD HH:mm:ss");

      const orderItems =
        config.role !== "staff"
          ? allOrderItems.filter((item) => item.order_id === order.order_id)
          : [];

      return {
        ...order,
        name: `${order.user.first_name} ${order.user.last_name}`,
        date: orderDate,
        order_order_items: orderItems,
      };
    });
  };

  const navigate = useNavigate();

  const handleOrderAnswerClick = (row: any) => {
    navigate(`/order_answer/${row.order_id}`, { state: { row } });
  };

  const handleOrderInfoClick = (row: any) => {
    navigate(`/order_info/${row.order_id}`, { state: { row } });
  };

  const getActionsButton = (row: any) => {
    return (
      <>
        {(config.role === "admin" || config.role === "manager") && (
          <>
            <IconButton
              aria-label="edit"
              size="small"
              color="primary"
              onClick={() => handleOpenEditOrderDialog(row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleOpenDeleteDialog(row)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="info"
              size="small"
              onClick={() => handleOrderInfoClick(row)}
            >
              <InfoIcon />
            </IconButton>
          </>
        )}
        <IconButton
          aria-label="info"
          size="small"
          onClick={() => handleOrderAnswerClick(row)}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </>
    );
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleToggleView = () => {
    setIsCalendarMode((prev) => !prev);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">Order</Typography>
        {(config.role === "admin" || config.role === "manager") && (
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderItemClick}
            >
              Order Items
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateItemClick}
            >
              Add Item
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateOrderClick}
            >
              Add Order
            </Button>
          </Box>
        )}
      </Box>

      {config.role === "staff" ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <GenericTable
              columnsConfig={columnConfig}
              tableType={"order"}
              rows={orders}
              getRows={getRows}
              setRows={setOrders}
              getActionsButton={getActionsButton}
            />
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Calendar View</Typography>
                  <IconButton onClick={handleToggleView}>
                    {isCalendarMode ? <ReorderIcon /> : <CalendarMonthIcon />}
                  </IconButton>
                </Box>

                {isCalendarMode ? (
                  <Calendar
                    user={selectedUser}
                    handleDateChange={handleDateChange}
                    highlightedDays={highlightedDays}
                    setHighlightedDays={setHighlightedDays}
                  />
                ) : (
                  <CalendarList user={selectedUser} />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <CollapseTable
          columnsConfig={columnConfig}
          detailColumns={
            config.role === "client" ? detailColumnsClient : detailColumnsAdmin
          }
          tableType={"order"}
          rows={orders}
          getRows={getRows}
          setRows={setOrders}
          handleOpenAddOrderOrderItemDialog={handleOpenAddOrderOrderItemDialog}
          handleOpenDeleteOrderOrderItemDialog={
            handleOpenDeleteOrderOrderItemDialog
          }
          getActionsButton={getActionsButton}
        />
      )}

      {(config.role === "admin" || config.role === "manager") && (
        <>
          <AddItemFormDialog
            open={openCreateDialog}
            handleClose={handleCloseCreateDialog}
          />
          <OrderItemFormDialog
            open={openOrderItemDialog}
            handleClose={handleCloseOrderItemDialog}
          />
          <AddOrderFormDialog
            open={openCreateOrderDialog}
            handleClose={handleCloseCreateOrderDialog}
            addOrder={handleAddOrder}
          />

          {orderOrderItemToAdd && (
            <AddOrderOrderItemFormDialog
              open={openAddOrderOrderItemDialog}
              handleClose={handleCloseAddOrderOrderItemDialog}
              order={orderOrderItemToAdd}
              addOrderOrderItems={handleAddOrderOrderItem}
            />
          )}
          {orderToEdit && (
            <EditOrderFormDialog
              open={openEditOrderDialog}
              handleClose={handleCloseEditOrderDialog}
              row={orderToEdit}
              onSave={handleSaveEditOrder}
            />
          )}
          {orderToDelete && (
            <DeleteFormDialog
              open={openDeleteDialog}
              handleClose={handleCloseDeleteDialog}
              order={orderToDelete}
              onDelete={handleDeleteOrderClick}
            />
          )}

          {orderToDelete && orderOrderItemToDelete && (
            <DeleteFormDialog
              open={openDeleteOrderOrderItemDialog}
              handleClose={handleCloseDeleteOrderOrderItemDialog}
              order={orderToDelete}
              order_order_item={orderOrderItemToDelete}
              onDelete={handleDeleteOrderOrderItemClick}
              onEdit={handleSaveEditOrder}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default OrderPage;
