import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosResponse } from "axios";
import api from "../api";
import { OrderItemCreate } from "../../api/api";

interface AddItemFormDialogProps {
  open: boolean;
  handleClose: () => void;
}

const AddItemFormDialog: React.FC<AddItemFormDialogProps> = ({
  open,
  handleClose,
}) => {
  const [itemCreate, setItemCreate] = React.useState<OrderItemCreate>({
    description: "",
    price: 0,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>,
  ) => {
    if ("name" in event.target) {
      const { name, value } = event.target as HTMLInputElement;
      setItemCreate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      console.warn("Unexpected target:", event.target);
    }
  };
  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response: AxiosResponse<any> =
        await api.order_items.postOrderItemOrderItemsPost(itemCreate);

      handleClose();
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Add Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the necessary information to add an item:
        </DialogContentText>

        <TextField
          margin="dense"
          id="description"
          name="description"
          label="Description"
          fullWidth
          variant="standard"
          required
          value={itemCreate.description}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="price"
          name="price"
          label="Price"
          type="number"
          fullWidth
          variant="standard"
          required
          value={itemCreate.price}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemFormDialog;
