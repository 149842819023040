import * as React from "react";
import Box from "@mui/material/Box";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import GenericTable from "../components/Table/Table";
import AddFormDialog from "../components/FormDialog/AddFormDialog";
import { User, UserCollections, OrderStaffCreate } from "../api/api";
import api from "../components/api";
import { AxiosResponse } from "axios";
import EditFormDialog from "../components/FormDialog/EditFormDialog";
import EditIcon from "@mui/icons-material/Edit";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Drawer from "@mui/material/Drawer";
import Calendar from "../components/Calendar/Calendar";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";

interface ManagementPageProps {
  userType: "admin" | "client" | "staff";
}

const DrawerWidth = 400;

const MainContent = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: DrawerWidth,
  }),
}));

const ManagementPage: React.FC<ManagementPageProps> = ({ userType }) => {
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [users, setUsers] = React.useState<User[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState<any | null>(null);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [highlightedDays, setHighlightedDays] = React.useState<Dayjs[]>([]);
  const [orderStaffToDelete, setOrderStaffToDelete] = React.useState<any>(null);
  const [orderStaffs, setOrderStaffs] = React.useState<Map<string, any>>(
    new Map(),
  );

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const handleOpenEditDialog = (row: any) => {
    setUserToEdit(row);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setUserToEdit(null);
    setOpenEditDialog(false);
  };

  const handleSaveEditUser = (updatedUser: User) => {
    if (handleEditUser) handleEditUser(updatedUser);
    handleCloseEditDialog();
  };

  const handleCreateButtonClick = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleAddUser = (newUser: User) => {
    setUsers([newUser, ...users]);
  };

  const handleEditUser = (updatedUser: User) => {
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) =>
        user.user_id === updatedUser.user_id ? updatedUser : user,
      );

      updatedUsers.sort((a, b) => {
        if (a.user_id === updatedUser.user_id) return -1;
        if (b.user_id === updatedUser.user_id) return 1;
        return 0;
      });

      return updatedUsers;
    });
  };

  const getTitle = () => {
    switch (userType) {
      case "admin":
        return "Admin Management";
      case "client":
        return "Client Management";
      case "staff":
        return "Staff Management";
      default:
        return "Management";
    }
  };

  const columnsConfig = {
    client: [
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Client Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
    staff: [
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "extras.profession", label: "Profession", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
    admin: [
      { id: "role", label: "Role", minWidth: 100 },
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
  };

  const concatName = (response: AxiosResponse<UserCollections, any>) => {
    return response.data.contents.map((user) => {
      return {
        ...user,
        name: user.first_name + " " + user.last_name,
      };
    });
  };

  const getRows = async () => {
    if (userType === "client") {
      const response =
        await api.users.getUsersRoleUsersRoleTargetRoleGet("client");
      return concatName(response);
    } else if (userType === "staff") {
      const response =
        await api.users.getUsersRoleUsersRoleTargetRoleGet("staff");
      return concatName(response);
    } else if (userType === "admin") {
      const [adminResponse, managerResponse] = await Promise.all([
        api.users.getUsersRoleUsersRoleTargetRoleGet("manager"),
        api.users.getUsersRoleUsersRoleTargetRoleGet("admin"),
      ]);
      return [...concatName(managerResponse), ...concatName(adminResponse)];
    }
    return [];
  };

  const handleCalendarOpen = (user: User) => {
    setSelectedUser(user);
    setCalendarOpen(true);
    setHighlightedDays([]);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
    setSelectedUser(null);
  };

  const getActionsButton = (row: any) => {
    return (
      <>
        <IconButton color="primary" onClick={() => handleOpenEditDialog(row)}>
          <EditIcon />
        </IconButton>
        {userType === "staff" && (
          <IconButton color="secondary" onClick={() => handleCalendarOpen(row)}>
            <CalendarMonthIcon />
          </IconButton>
        )}
      </>
    );
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleUnavailableClick = async () => {
    if (!selectedUser || !selectedDate) return;

    const orderStaffCreate: OrderStaffCreate = {
      date: selectedDate.format("YYYY-MM-DD"),
      order_id: null,
      user_id: selectedUser.user_id,
    };

    try {
      const postResponse =
        await api.order_staffs.postOrderStaffOrderStaffsPost(orderStaffCreate);

      setHighlightedDays((prevDays) => {
        const newDays = [...prevDays, selectedDate];
        return newDays;
      });

      setOrderStaffs((prevOrderStaffs) => {
        const newOrderStaffs = new Map(prevOrderStaffs);
        newOrderStaffs.set(
          selectedDate.format("YYYY-MM-DD"),
          postResponse.data,
        );
        return newOrderStaffs;
      });

      setSelectedDate(null);
    } catch (error) {
      console.error("Error creating OrderStaff:", error);
    }
  };

  const handleAvailableClick = async () => {
    if (!selectedUser || !selectedDate) return;

    const orderStaff = orderStaffs.get(selectedDate.format("YYYY-MM-DD"));

    if (orderStaff && Object.keys(orderStaff.order).length !== 0) {
      setOrderStaffToDelete(orderStaff);
      setOpenConfirmDialog(true);
    } else {
      try {
        const deleteResponse =
          await api.order_staffs.deleteOrderStaffOrderStaffsOrderStaffIdDelete(
            orderStaff.order_staff_id,
          );
        setHighlightedDays((prevDays) => {
          const newDays = prevDays.filter(
            (day) => !day.isSame(selectedDate, "day"),
          );
          return newDays;
        });

        setOrderStaffs((prevOrderStaffs) => {
          const newOrderStaffs = new Map(prevOrderStaffs);
          newOrderStaffs.delete(selectedDate.format("YYYY-MM-DD"));
          return newOrderStaffs;
        });
      } catch (error) {
        console.error("Error removing OrderStaff:", error);
      }
    }
  };

  const handleConfirmDelete = async () => {
    if (orderStaffToDelete) {
      try {
        await api.order_staffs.deleteOrderStaffOrderStaffsOrderStaffIdDelete(
          orderStaffToDelete.order_staff_id,
        );
        setHighlightedDays((prevDays) => {
          const newDays = prevDays.filter(
            (day) => !day.isSame(selectedDate, "day"),
          );
          return newDays;
        });

        setOrderStaffs((prevOrderStaffs) => {
          const newOrderStaffs = new Map(prevOrderStaffs);
          if (selectedDate)
            newOrderStaffs.delete(selectedDate.format("YYYY-MM-DD"));
          return newOrderStaffs;
        });
      } catch (error) {
        console.error("Error removing OrderStaff:", error);
      } finally {
        setOpenConfirmDialog(false);
        setOrderStaffToDelete(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setOrderStaffToDelete(null);
  };

  useEffect(() => {
    console.log("orderStaffs updated:", orderStaffs);
  }, [orderStaffs]);

  const isDateHighlighted = (date: Dayjs | null) =>
    date ? highlightedDays.some((day) => day.isSame(date, "day")) : false;

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <MainContent open={calendarOpen}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "1rem" }}
        >
          <Typography variant="h5">{getTitle()}</Typography>
          <IconButton color="primary" onClick={handleCreateButtonClick}>
            <PersonAddIcon />
          </IconButton>
        </Box>
        <GenericTable
          columnsConfig={columnsConfig[userType]}
          tableType={userType}
          rows={users}
          getRows={getRows}
          setRows={setUsers}
          getActionsButton={getActionsButton}
        />
        <AddFormDialog
          open={openCreateDialog}
          handleClose={handleCloseCreateDialog}
          userType={userType}
          addUser={handleAddUser}
        />
        {userToEdit && (
          <EditFormDialog
            open={openEditDialog}
            handleClose={handleCloseEditDialog}
            user={userToEdit}
            onSave={handleSaveEditUser}
          />
        )}
      </MainContent>
      {selectedUser && (
        <Drawer
          anchor="right"
          variant="persistent"
          open={calendarOpen}
          onClose={handleCalendarClose}
          PaperProps={{ sx: { width: DrawerWidth, padding: 2 } }}
        >
          <Box sx={{ paddingTop: 8 }}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={handleCalendarClose} color="default">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ marginLeft: 2 }}>
                Schedule of{" "}
                {selectedUser.first_name + " " + selectedUser.last_name}
              </Typography>
            </Box>
            <Box>
              <Calendar
                user={selectedUser}
                handleDateChange={handleDateChange}
                highlightedDays={highlightedDays}
                setHighlightedDays={setHighlightedDays}
                orderStaffToDelete={orderStaffToDelete}
                setOrderStaffToDelete={setOrderStaffToDelete}
                orderStaffs={orderStaffs}
                setOrderStaffs={setOrderStaffs}
              />
              <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleUnavailableClick}
                  disabled={!selectedDate || isDateHighlighted(selectedDate)}
                  sx={{ marginRight: 1 }}
                >
                  Unavailable
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAvailableClick}
                  disabled={!selectedDate || !isDateHighlighted(selectedDate)}
                >
                  Available
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
      <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this staff from the order? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{
              backgroundColor: "#2196f3",
              color: "white",
              "&:hover": { backgroundColor: "#64b5f6" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManagementPage;
