import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { OrderStatusCreate } from "../../api/api";
import api from "../api";

interface VerticalLinearStepperProps {
  order: any;
  handleEditedStatus: (status: any) => void;
  statusCategory:
    | "order"
    | "logistics"
    | "post_production"
    | "wedding_production";
}

const formatTitle = (statusCategory: string) => {
  return statusCategory
    .replace(/_/g, " ")
    .replace(/\b\w/g, (letter) => letter.toUpperCase());
};

// Define the step type with non-nullable fields
interface StepType {
  label: string;
  description: string;
  complete_date: string;
  status_id: string;
}

export default function VerticalLinearStepper({
  order,
  handleEditedStatus,
  statusCategory,
}: VerticalLinearStepperProps) {
  const [steps, setSteps] = React.useState<StepType[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");

  React.useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response =
          await api.status.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
            statusCategory,
          );

        const fetchedSteps: StepType[] = response.data.contents.map(
          (status: any) => ({
            label: status.status,
            description: `Description for ${status.status}`,
            complete_date: "", // Placeholder for complete_date
            status_id: status.status_id,
          }),
        );

        const orderStatusResponse =
          await api.order_status.getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
            order.order_id,
          );

        const orderStatuses = orderStatusResponse.data.contents;

        // Update steps with complete_date and description
        const updatedSteps: StepType[] = fetchedSteps.map((step) => {
          const relevantStatus = orderStatuses
            .filter((status: any) => status.status_id === step.status_id)
            .sort(
              (a: any, b: any) =>
                new Date(b.complete_date).getTime() -
                new Date(a.complete_date).getTime(),
            )[0];

          return {
            ...step,
            complete_date: relevantStatus ? relevantStatus.complete_date : "",
            description: relevantStatus
              ? relevantStatus.description || step.description
              : step.description,
          };
        });

        setSteps(updatedSteps);

        const lastCompletedStatus = orderStatuses
          .filter(
            (status: any) => status.status.status_category === statusCategory,
          )
          .sort(
            (a: any, b: any) => b.status.status_rank - a.status.status_rank,
          )[0];

        if (lastCompletedStatus) {
          const initialStep = updatedSteps.findIndex(
            (step) => step.status_id === lastCompletedStatus.status_id,
          );
          setActiveStep(initialStep >= 0 ? initialStep + 1 : 1);
        }
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchStatuses();
  }, [statusCategory, order]);

  const handleNext = async () => {
    const nextStep = activeStep + 1;
    if (nextStep < steps.length) {
      setActiveStep(nextStep);
      await addOrderStatus(nextStep);
    }
    setDialogOpen(false);
  };

  const addOrderStatus = async (stepIndex: number) => {
    const currentStep = steps[stepIndex - 1];

    if (!currentStep) return;

    const orderStatusCreate: OrderStatusCreate = {
      complete_date: new Date().toISOString(),
      order_id: order.order_id,
      status_id: currentStep.status_id,
      description: comment,
    };

    try {
      const response =
        await api.order_status.postOrderStatusOrderStatusesPost(
          orderStatusCreate,
        );
      handleEditedStatus(response.data);
      setComment("");
    } catch (error) {
      console.error("Failed to add order status:", error);
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxHeight: "400px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 2,
          paddingLeft: 3,
          paddingTop: 1,
        }}
      >
        {/* Use the formatted title based on statusCategory */}
        <Typography variant="h6" gutterBottom>
          {formatTitle(statusCategory)} Progress
        </Typography>
      </Box>
      <CardContent sx={{ overflowY: "auto" }}>
        <Box sx={{ maxWidth: "500px", ml: 2 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.status_id} active={index <= activeStep}>
                <StepLabel
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography variant="body2">{step.description}</Typography>
                  {step.complete_date && (
                    <Typography variant="caption" color="textSecondary">
                      Completed on:{" "}
                      {new Date(step.complete_date).toLocaleDateString()}
                    </Typography>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index === activeStep && (
                        <Button
                          variant="contained"
                          onClick={() => setDialogOpen(true)}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={index === steps.length - 1}
                        >
                          {index === steps.length - 1 ? "Finish" : "Done"}
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>All steps completed - you're finished</Typography>
            </Paper>
          )}
        </Box>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Add comment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add comment to this order status
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              variant="standard"
              onChange={(e) => setComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleNext}>Add</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}
