import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../api";
import { OrderStaff, User } from "../../api";

interface AlignItemsListProps {
  user: User;
}

export default function AlignItemsList({ user }: AlignItemsListProps) {
  const [orderStaffs, setOrderStaffs] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchOrderStaffs = async () => {
      try {
        const response =
          await api.order_staffs.getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
            user.user_id,
          );
        setOrderStaffs(response.data.contents);
      } catch (error) {
        console.error("Error fetching order staffs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderStaffs();
  }, [user]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <List sx={{ width: "100%", height: "100%", bgcolor: "background.paper" }}>
      {orderStaffs.map((orderStaff, index) => (
        <React.Fragment key={orderStaff.order_staff_id}>
          <ListItem alignItems="flex-start">
            <Box sx={{ minWidth: 120, textAlign: "center" }}>
              <Typography variant="h6" component="span" color="text.primary">
                {orderStaff.order && orderStaff.order.date
                  ? new Date(orderStaff.order.date).toLocaleDateString()
                  : new Date(orderStaff.date).toLocaleDateString()}
              </Typography>
            </Box>
            <ListItemText
              primary={
                orderStaff.order && Object.keys(orderStaff.order).length > 0
                  ? orderStaff.order.type
                  : "Unavailable"
              }
              secondary={
                orderStaff.order && Object.keys(orderStaff.order).length > 0 ? (
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {orderStaff.order.status}
                  </Typography>
                ) : null
              }
            />
          </ListItem>
          {index < orderStaffs.length - 1 && (
            <Divider variant="inset" component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
