import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DescriptionIcon from "@mui/icons-material/Description";
import QuizIcon from "@mui/icons-material/Quiz";
import { useAuth } from "../auth_context";
import SidebarItem from "./SideBarItem/SideBarItem";

interface SideBarProps {
  drawerWidth: number;
  open?: boolean;
  toggleDrawer?: () => void;
}

interface DrawerProps {
  drawerWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ theme, open, drawerWidth }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Sidebar: React.FC<SideBarProps> = (props) => {
  const { role, isLoggedIn } = useAuth();

  return (
    <Drawer
      variant="permanent"
      open={props.open}
      drawerWidth={props.drawerWidth}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={props.toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {isLoggedIn && (
          <SidebarItem to="/" icon={<DashboardIcon />} primary="Dashboard" />
        )}
        {(role === "admin" || role === "manager") && (
          <>
            {role === "admin" && (
              <SidebarItem
                to="/admin-management"
                icon={<AdminPanelSettingsRoundedIcon />}
                primary="Admin Management"
              />
            )}

            <SidebarItem
              to="/staff-management"
              icon={<Diversity3Icon />}
              primary="Staff Management"
            />
            <SidebarItem
              to="/client-management"
              icon={<PeopleIcon />}
              primary="Client Management"
            />
            <SidebarItem
              to="/question"
              icon={<QuizIcon />}
              primary="Question"
            />
          </>
        )}
        {isLoggedIn && (
          <SidebarItem to="/order" icon={<DescriptionIcon />} primary="Order" />
        )}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        {isLoggedIn ? (
          <SidebarItem to="/login" icon={<LogoutIcon />} primary="Logout" />
        ) : (
          <SidebarItem to="/login" icon={<LoginIcon />} primary="Login" />
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
