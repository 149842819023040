import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import { User, UserPatch } from "../../api/api";
import api from "../api";
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";

interface EditFormDialogProps {
  open: boolean;
  handleClose: () => void;
  user: User;
  onSave: (user: User) => void;
}

const EditFormDialog: React.FC<EditFormDialogProps> = ({
  open,
  handleClose,
  user,
  onSave,
}) => {
  const [userPatch, setUserPatch] = React.useState<UserPatch>({
    first_name: user.first_name,
    last_name: user.last_name,
    role: user.role,
    extras: {
      address: user.extras?.address,
      pregnancy_due_date: user.extras?.pregnancy_due_date,
      baby_birth_date: user.extras?.baby_birth_date,
      profession: user.extras?.profession || "",
      source: user.extras?.source || "",
      comment: user.extras?.comment || "",
    },
    user_email: user.user_email,
    phone_number: user.phone_number,
  });

  const [professionError, setProfessionError] = React.useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "pregnancy_due_date" || name === "baby_birth_date") {
      setUserPatch((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          [name]: value ? dayjs(value).format("YYYY-MM-DD") : null,
        },
      }));
    } else if (name === "address") {
      setUserPatch((prevState) => ({
        ...prevState,
        extras: {
          ...prevState.extras,
          [name]: value,
        },
      }));
    } else {
      setUserPatch((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setUserPatch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Dayjs | null, name: string) => {
    setUserPatch((prevState) => ({
      ...prevState,
      extras: {
        ...prevState.extras,
        [name]: date,
      },
    }));
  };

  const handleProfessionChange = (event: SelectChangeEvent<string>) => {
    setUserPatch((prevState) => ({
      ...prevState,
      extras: {
        ...prevState.extras,
        profession: event.target.value,
      },
    }));
  };

  const handleSourceChange = (event: SelectChangeEvent<string>) => {
    setUserPatch((prevState) => ({
      ...prevState,
      extras: {
        ...prevState.extras,
        source: event.target.value,
      },
    }));
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response: AxiosResponse =
        await api.users.patchUsersUserIdUsersUserIdPatch(
          user.user_id,
          userPatch,
        );
      const data = {
        ...response.data,
        name: response.data.first_name + " " + response.data.last_name,
      };
      onSave(data);

      handleClose();
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire :", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>
        Edit {user.role === "client" ? "Client" : "User"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please update the necessary information:
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="first_name"
          name="first_name"
          label="First Name"
          fullWidth
          variant="standard"
          required
          value={userPatch.first_name}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="last_name"
          name="last_name"
          label="Last Name"
          fullWidth
          variant="standard"
          value={userPatch.last_name}
          onChange={handleInputChange}
          required
        />

        <TextField
          margin="dense"
          id="user_email"
          name="user_email"
          label="Email"
          fullWidth
          variant="standard"
          required
          value={userPatch.user_email}
          onChange={handleInputChange}
        />

        <TextField
          margin="dense"
          id="phone_number"
          name="phone_number"
          label="Phone Number"
          fullWidth
          variant="standard"
          required
          value={userPatch.phone_number || ""}
          onChange={handleInputChange}
        />

        {user.role === "client" && (
          <>
            <Box mb={1}>
              <TextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                fullWidth
                variant="standard"
                value={userPatch.extras?.address || ""}
                onChange={handleInputChange}
              />
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box mb={1}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Pregnancy due date"
                    value={
                      userPatch.extras?.pregnancy_due_date
                        ? dayjs(userPatch.extras.pregnancy_due_date)
                        : null
                    }
                    onChange={(date) =>
                      handleDateChange(date, "pregnancy_due_date")
                    }
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </DemoContainer>
              </Box>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Baby Birthdate"
                  value={
                    userPatch.extras?.baby_birth_date
                      ? dayjs(userPatch.extras.baby_birth_date)
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "baby_birth_date")}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <FormControl fullWidth margin="dense">
              <InputLabel id="source-label">Source</InputLabel>
              <Select
                labelId="source-label"
                id="source"
                name="source"
                value={userPatch.extras?.source ?? ""}
                onChange={handleSourceChange}
                label="Source"
              >
                <MenuItem value="xiaohongshu">XiaoHongshu</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="wechat">Wechat</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="referrals">Referrals</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            {(userPatch.extras?.source === "referrals" ||
              userPatch.extras?.source === "other") && (
              <TextField
                margin="dense"
                id="comment"
                name="comment"
                label="Comment"
                fullWidth
                variant="standard"
                value={userPatch.extras?.comment || ""}
                onChange={handleInputChange}
              />
            )}
          </>
        )}

        {(user.role === "admin" || user.role === "manager") && (
          <FormControl fullWidth margin="dense" required>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              value={userPatch.role || ""}
              onChange={handleRoleChange}
              label="Role"
            >
              <MenuItem value="Manager">Manager</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
          </FormControl>
        )}

        {user.role === "staff" && (
          <FormControl
            fullWidth
            margin="dense"
            required
            error={professionError}
          >
            <InputLabel id="profession-label">Profession</InputLabel>
            <Select
              labelId="profession-label"
              id="profession"
              value={userPatch.extras?.profession ?? ""}
              onChange={handleProfessionChange}
              label="Profession"
            >
              <MenuItem value="Photographer">Photographer</MenuItem>
              <MenuItem value="Videographer">Videographer</MenuItem>
              <MenuItem value="Makeup">Makeup</MenuItem>
              <MenuItem value="Staff">Staff</MenuItem>
              <MenuItem value="Editor">Editor</MenuItem>
            </Select>
            {professionError && (
              <FormHelperText>Please select a profession.</FormHelperText>
            )}
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFormDialog;
